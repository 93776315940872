import { TStatics } from "../../api/modules/Statics/types";

export const getServiceTypeCopy = (
  serviceTypeId?: number,
  statics?: TStatics
) => {
  const { serviceTypes } = statics || {};
  const currentJobType = serviceTypes?.find(({ id }) => id === serviceTypeId);
  let serviceName;
  if (serviceTypeId === 1) {
    serviceName = "Inspection";
  } else if (serviceTypeId === 2) {
    serviceName = "Pump Out";
  } else if (serviceTypeId === 3) {
    serviceName = "Repair";
  } else {
    serviceName = currentJobType?.name;
  }
  return {
    serviceType: serviceName,
  };
};
